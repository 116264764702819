import React, {useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans, useI18next, I18nextContext} from "gatsby-plugin-react-i18next";
import {graphql, useStaticQuery} from 'gatsby';
import Accordion from '../../../ui/legacy/Accordion/Accordion'
import YouTube from "../../../video-player/YouTube";
import UpgraderModal from "../../../modals/upgrader-modal";
let youTubeVideos = {
  "rfid": {
    "de": "https://www.youtube.com/embed/videoseries?list=PLXWP8g8Rnc_ZKyGd1BIrACwB5Gt4_wofy",
    "en": "https://www.youtube.com/embed/videoseries?list=PLXWP8g8Rnc_Y3V4KeMddZqrCSE6lhFoYE",
    "es": "https://www.youtube.com/embed/videoseries?list=PLXWP8g8Rnc_bn75m9zQVyntqol5yCsceg",
    "fr": "https://www.youtube.com/embed/videoseries?list=PLXWP8g8Rnc_bqnxLZyut13OesYDxPpI4W"
  },
  "keypad": {
    "de": "https://www.youtube.com/embed/videoseries?list=PLXWP8g8Rnc_a7R28ZivO_-fd8Y-MTyZyj",
    "en": "https://www.youtube.com/embed/videoseries?list=PLXWP8g8Rnc_YgRaqo5bk3bsSfVb2KYvt3",
    "es": "https://www.youtube.com/embed/videoseries?list=PLXWP8g8Rnc_YMPHWCheMMqlPiZZ22ZLCk",
    "fr": "https://www.youtube.com/embed/videoseries?list=PLXWP8g8Rnc_bonduB1wy0onXVwUqN26bq"
  }
}

const Tab = (props) => {
  const data = useStaticQuery(graphql`
    query {
      keypad: allContentfulProductSupportSection(filter: {title: {eq: "4G Vertical Keypad"}}) {
        edges {
          node {
            title
            catalog {
              title
              file {
                url
                fileName
                contentType
              }
            }
            dataSheets {
              title
              file {
                url
                fileName
                contentType
              }
            }
            installGuides {
              title
              file {
                url
                fileName
                contentType
              }
            }
            productGuides {
              title
              file {
                url
                fileName
                contentType
              }
            }
            salesSheet {
              title
              file {
                url
                fileName
                contentType
              }
            }
            youTubeVideos {
              keypad {
                de
                en
                es
                fr
              }
            }
          }
        }
      }
      rfid: allContentfulProductSupportSection(filter: {title: {eq: "4G Vertical RFID"}}) {
        edges {
          node {
            title
            catalog {
              title
              file {
                url
                fileName
                contentType
              }
            }
            dataSheets {
              title
              file {
                url
                fileName
                contentType
              }
            }
            installGuides {
              title
              file {
                url
                fileName
                contentType
              }
            }
            productGuides {
              title
              file {
                url
                fileName
                contentType
              }
            }
            salesSheet {
              title
              file {
                url
                fileName
                contentType
              }
            }
            youTubeVideos {
              keypad {
                de
                en
                es
                fr
              }
            }
          }
        }
      }
    }
    
  `);
  const {changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const [toggleState, setToggleState] = useState(1);
  const keypadDocuments = data.keypad.edges[0].node;
  let keypadDatasheets = keypadDocuments.dataSheets;
  let keypadInstallGuides = keypadDocuments.installGuides;
  let keypadProductGuides = keypadDocuments.productGuides;

  const rfidDocuments = data.rfid.edges[0].node;
  let rfidDatasheets = rfidDocuments.dataSheets;
  let rfidInstallGuides = rfidDocuments.installGuides;
  let rfidProductGuides = rfidDocuments.productGuides;



  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };

  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            A Series Vertical <Trans>keypad</Trans>
          </li>
          <li
              className={getActiveClass(2, 'active')}
              onClick={() => toggleTab(2)}
          >
            A Series Vertical <Trans>rfid</Trans>
          </li>
        </ul>
        <div className="tab-content-container">
          <div className={"tab-content "+getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'../../../../images/locks/fourth-generation/keypad-vertical.png'}
                  loading={'lazy'}
                  width={'268'}
                  height={'300'}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Aspire Smart Lock"
              />
            </div>
            <div className="lists">
              <Accordion clsName={'next-support'}>
                <div label="Data Sheets">
                  <div className="step-images">
                    <ul>
                      {
                        keypadDatasheets.map( (doc, index) => (
                                <li key={index}>
                                  <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                </li>
                            )
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div label="Install Guides">
                  <div className="step-images">
                    <ul>
                      {
                        keypadInstallGuides.map( (doc, index) => (
                                <li key={index}>
                                  <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                </li>
                            )
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div label="Product Guides">
                  <div className="step-images">
                    <ul>
                      {
                        keypadProductGuides.map( (doc, index) => (
                                <li key={index}>
                                  <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                </li>
                            )
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div label="Standard Keypad Instructional Videos">
                  <YouTube videos={youTubeVideos.keypad} lang={context.language} videoTitle={'Standard Keypad Instructional Videos'} />
                </div>
              </Accordion>
            </div>
            <UpgraderModal showLink={true}/>
          </div>
          <div className={"tab-content "+getActiveClass(2, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'../../../../images/locks/fourth-generation/rfid-vertical.png'}
                  loading={'lazy'}
                  width={'268'}
                  height={'300'}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Aspire Smart Lock"
              />
            </div>
            <div className="lists">
              <Accordion clsName={'legacy-support'}>
                <div label="Data Sheets">
                  <div className="step-images">
                    <ul>
                      {
                        rfidDatasheets.map( (doc, index) => (
                                <li key={index}>
                                  <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                </li>
                            )
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div label="Install Guides">
                  <div className="step-images">
                    <ul>
                      {
                        rfidInstallGuides.map( (doc, index) => (
                                <li key={index}>
                                  <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                </li>
                            )
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div label="Product Guides">
                  <div className="step-images">
                    <ul>
                      {
                        rfidProductGuides.map( (doc, index) => (
                                <li key={index}>
                                  <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                </li>
                            )
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div label="Standard RFID Instructional Videos">
                  <YouTube videos={youTubeVideos.rfid} lang={context.language} videoTitle={'Standard Keypad Instructional Videos'} />
                </div>
              </Accordion>
            </div>
            <UpgraderModal showLink={true}/>
          </div>
        </div>

      </div>
  );
};

export default Tab;